import React, {useState, useEffect} from 'react';
import './Header.css';


interface HeaderProps {
    measurementUnits:string;
    setMeasurementUnits: any;
}

const Header: React.FC<HeaderProps> = ({measurementUnits,setMeasurementUnits}) => {

    const handleChange = (event:any) => {
        const selectedValue = event.target.value;
        setMeasurementUnits(selectedValue);
      };

      const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      useEffect(() => {
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    
    return (

        
        <div className="navbar">
            <div className="navbar-container">
                <div className='navbar-brand'>
                    <img src="assets/img/logo-eolos.svg" alt="Eolos" className="navbar-eolos"/>
                </div>
                <div className='navbar-brand'>
                    <img src="assets/img/logo-sailorsApp.svg" alt="Sailors App"/>
                </div>
                <div className='navbar-brand'>
                    { (windowSize.width < 992) ? (
                        <select value={measurementUnits} onChange={handleChange}>
                            <option value="INT">INT</option>
                            <option value="IMP">IMP</option>
                        </select>
                    ):(
                        <>
                        <label>Units:</label> 
                        <select value={measurementUnits} onChange={handleChange}>
                            <option value="INT">International</option>
                            <option value="IMP">Imperial</option>
                        </select>
                        </>
                    )
                    
                    }
                </div>
            </div>
       </div>
    );
  };
  
  export default Header;