import React from 'react';
import './Sidebar.css';


interface SidebarProps {
    onToggle: (click: any) => void;
    isActive: boolean;
    htmlDetailBouy: string; 
    noData: boolean;
    mobile: boolean;
    bouysLoadMap: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ onToggle, isActive, htmlDetailBouy, noData, mobile, bouysLoadMap }) => {
  return ( 
        <div className={`sidebar  ${bouysLoadMap ? '' : 'sidebar-hidden'} ${isActive ? 'sidebar-close' : ''}`}>
            
            { mobile ? (
            <div className='sidebar-buttons'>
                <button className={`${isActive ? 'button-dades' : ''}`} onClick={ () => onToggle("DADES")} >DATA</button>
                <button className={`${isActive ? '' : 'button-map'}`} onClick={() => onToggle("MAP") } >MAP</button>
            </div>
            ):(
                <div className="sidebar-button" onClick={() => onToggle("") } >
                <img src="assets/img/icon-chevron.svg" alt="Open"/>
                </div>
            )
            }

           
            <div className="sidebar-content">
            {
                htmlDetailBouy ? (
                <>
                    <div dangerouslySetInnerHTML={{ __html: htmlDetailBouy }} />
                </>
                ) : (


                    noData ? 
                    <>
                        <div className='sidebar-message'>
                            <p>Hello! Explore buoy data by <strong>tapping on a buoy <img src="assets/img/icon-pointer.png" alt="buoy" /> on the map</strong>.</p>
                            <img src="assets/img/img_selected-map.svg" alt="Tapping on a buoy on the map" /> 
                        </div>
                    </>
                    :
                    <>
                            <h1><span className="skeleton-item">Lorem isum blandit</span></h1>
                              <div className="skeleton-item ubication">
                                  <img src="assets/img/icon-pointer.svg" alt="Pointer map" /> <span>Barcelona, Catalunya</span>
                              </div>
                              <div className="date">
                                  <span className="skeleton-item">
                                      <img src="assets/img/icon-date.svg" alt="Date" /> 
                                  </span>
                                  <span className="skeleton-item">25/04/1987 ~ 03:00 AM</span>
                            </div>
                            <div className='list'>
                                <div className="list-item">
                                    <div className="list-item-icon skeleton-item">
                                        <img src="assets/img/icons/icono-e-default.svg" alt="Eolos" />
                                    </div>
                                    <div className="list-item-content"> 
                                        <div className="list-item-col">
                                            <span className="title skeleton-item">Label text</span>
                                            <span className="text skeleton-item">898,3m</span>
                                        </div>
                                        <div className="list-item-col">
                                            <span className="title skeleton-item">Label text</span>
                                            <span className="text skeleton-item">88,3m</span>
                                        </div>
                                        <div className="list-item-col">
                                            <span className="title skeleton-item">Label text</span>
                                            <span className="text skeleton-item">88,3m</span>
                                        </div>      
                                    </div>
                                </div>
                                <div className="list-item">
                                    <div className="list-item-icon skeleton-item">
                                        <img src="assets/img/icons/icono-e-default.svg" alt="Eolos" />
                                    </div>
                                    <div className="list-item-content"> 
                                        <div className="list-item-col">
                                            <span className="title skeleton-item">Label text</span>
                                            <span className="text skeleton-item">898,3m</span>
                                        </div>
                                        <div className="list-item-col">
                                            <span className="title skeleton-item">Label text</span>
                                            <span className="text skeleton-item">88,3m</span>
                                        </div>
                                        <div className="list-item-col">
                                            <span className="title skeleton-item">Label text</span>
                                            <span className="text skeleton-item">88,3m</span>
                                        </div>      
                                    </div>
                                </div>
                                <img src="assets/img/icon-dots.svg" alt="..." className="dots" />
                            </div>
                    </>
                  )
                
                }
            </div>
        </div>
            
  );
};

export default Sidebar;